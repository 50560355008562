// SrA Matczak, Johnathon
// 2024-01-19
//

@import "@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "@blueprintjs/select/lib/css/blueprint-select.css";

//@import "node_modules/sass-basis/src/css/basis";
@import "theme.scss";

@viewport {
  width: device-width;
  zoom: 1;
}

@-ms-viewport {
  width: device-width;
}

:root {
  color-scheme: dark; // Dark scrollbar on Desktop
}

body {
  background: $primary-color;
}

@media (orientation: portrait) {}

@media (orientation: portrait) and (max-height: 932px) {
  body {
    margin: 0;
  }

  .iris-form {
    display: block !important;

    .bp5-card {
      box-shadow: none !important;
      border-radius: 0;
    }
  }
}

@media (orientation: landscape) {}

@media (orientation: landscape) and (max-width: 932px) {

  // Landscape viewports up to 932px width.
  body {
    margin: 0;
  }

  .iris-form {
    display: block !important;

    .bp5-card {
      box-shadow: none !important;
      border-radius: 0;
    }
  }

  .iris-form-section {
    display: block;

    .bp5-section-header {
      display: none;
    }
  }
}

@media (max-width: 932px) {}

@media (orientation: portrait) and (max-height: 1367px) {
  // Portrait viewports up to 1367px height.
}

@media (orientation: landscape) and (max-width: 1367px) {

  // Landscape viewports up to 1367px width.
  .form-container {
    .iris-form {
      display: flex;
      flex-wrap: on;
      gap: 5px;
    }
  }
}

@media (orientation: landscape) and (min-height: 1368px) {
  // Viewports exceeding 1368px in width (desktops/laptops)
}

@media (orientation: portrait) and (min-width: 1368px) {}

@media (min-width: 1368px) {}

.nomad {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;

  &.import-form {
    margin-top: 5vh;
  }

  .nav-card-list {
    margin-top: 25vh;

    .bp5-card {
      justify-content: space-between;
    }
  }
}

.form-container {
  max-width: 1680px;
  width: 100%;
  margin: 0 auto;

  .iris-form {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .code-controls {
      display: flex;
      width: 100%;
      padding: 8px;
      justify-content: flex-end;

      button {
        min-width: 125px;
      }
    }

    .iriscode-container {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      margin: 0 auto;
      width: 100%;
      height: 5em;
      cursor: pointer;

      @keyframes dramaticEffect {
        0% {
          color: $green5;
        }

        100% {
          color: $gray1;
        }
      }

      .iriscode {
        font-family: monospace;

        transition: color 0.2s;
        text-align: center;
        width: 90%;
        color: $gray1;

        -webkit-animation: dramaticEffect 1s 1;
        animation: dramaticEffect 1s 1;
      }

      .dramaticeffect {}

      :hover {
        color: $gray5;
      }

      :active {
        color: $blue3;
      }
    }

    .iris-form-section {
      flex: 1 0 500px;
    }
  }
}

.iris-select {
  select {
    min-height: 48px !important;
  }

  span {
    top: 16px !important;
  }

  button {
    width: 100%;
  }
}

.iris-segmentedcontrol {
  margin-bottom: 12px;
}

.apps-list {
  max-width: 1280px;
  margin: 0 auto;
  padding: 8px;

  .app {
    max-width: 500px;
    margin-bottom: 8px;

    img {
      width: 100% !important;
    }
  }
}

.noselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}